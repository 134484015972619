<template>
  <v-container fill-height fluid style="background: #fdf8f8">
    <v-row align="center" justify="center">
      <v-progress-circular
          indeterminate style="position: absolute"
          :color="mycolor"
          size="260"
          width="4"
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import setStyleConfig from "@/plugins/style/wholesalersConfig";

export default {
  name: "Splash",
  created() {
    this.generator()
    this.$router.push({name: "Login"})
  },
  data() {
    return {
      mycolor: "red",
      color: null
    }
  },
  watch:{
    color(){
      this.mycolor = this.color
    }
  },
  methods: {
    checkWholesaler() {
      setStyleConfig.loadStyle(window.location.host)
    },
    generator() {
      setInterval(()=>{
        this.color = '#' + (Math.random() * 0xFFFFFF << 0).toString(16)
      },1500)
    }
  }
}
</script>

<style scoped>

</style>