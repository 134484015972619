<template>
  <PageTemplate>
    <template slot="title">{{ this.$t("my_information") }}</template>
    <template slot="content">
      <v-container style="padding: 0; width: 800px;">
        <v-tabs
          centered
          color="white"
          dark
          class="elevation-10"
          background-color="accent"
        >
          <v-tab class="body-2">{{ this.$t("my_information") }}</v-tab>
          <v-tab-item>
            <v-form class="ma-8">
              <v-text-field
                prepend-icon="person"
                name="username"
                :label="$t('username')"
                :readonly="readOnly"
                v-model="userProfileData.username"
              />
              <v-text-field
                prepend-icon="mail_outline"
                :label="$t('email')"
                :readonly="readOnly"
                v-model="userProfileData.email"
              />
              <v-text-field
                prepend-icon="assignment_ind"
                :label="$t('name')"
                :readonly="readOnly"
                v-model="userProfileData.name"
              />
              <v-text-field
                prepend-icon="assignment_ind"
                :label="$t('last_name')"
                :readonly="readOnly"
                v-model="userProfileData.last_name"
              />
              <v-text-field
                prepend-icon="phone"
                :label="$t('phone_number')"
                :readonly="readOnly"
                v-model="userProfileData.phone_number"
              />
              <v-text-field
                prepend-icon="work_outline"
                :label="$t('tax_number')"
                :readonly="readOnly"
                v-model="userProfileData.tax_number"
              />
              <v-text-field
                prepend-icon="work_outline"
                :label="$t('company_name')"
                :readonly="readOnly"
                v-model="userProfileData.company_name"
              />
              <v-layout justify-center style="padding-bottom: 8px;">
                <v-btn
                  color="accent"
                  class="body-2"
                  @click="saveUserProfile()"
                  :disabled="disableSaveButton"
                >
                  {{ this.$t("save") }}
                </v-btn>
                <v-btn
                  class="body-2"
                  @click="editUserProfile()"
                  :disabled="disableEditButton"
                  >{{ this.$t("edit") }}
                </v-btn>
              </v-layout>
            </v-form>
          </v-tab-item>

          <v-tab class="body-2">{{ this.$t("change_password") }}</v-tab>
          <v-tab-item class="ma-8">
            <v-form ref="form" v-model="savePasswordButton" lazy-validation>
              <v-text-field
                prepend-icon="lock"
                :label="$t('current_password')"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                autocomplete="off"
                v-model="changePasswordData.current_password"
                :rules="passWordRules"
              />
              <v-text-field
                prepend-icon="lock"
                :label="$t('new_password')"
                v-model="changePasswordData.new_password"
                :append-icon="show2 ? 'visibility' : 'visibility_off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                autocomplete="off"
                :rules="newPassWordRules"
              />
              <v-text-field
                prepend-icon="lock"
                :label="$t('new_password_repeat')"
                v-model="new_password_again"
                :append-icon="show3 ? 'visibility' : 'visibility_off'"
                :type="show3 ? 'text' : 'password'"
                @click:append="show3 = !show3"
                autocomplete="off"
                :rules="[comparePasswords]"
              />
              <v-layout justify-center style="padding-bottom: 8px;">
                <v-btn
                  color="accent"
                  class="body-2"
                  :disabled="!savePasswordButton"
                  @click="savePassword()"
                >
                  {{ this.$t("save") }}
                </v-btn>
              </v-layout>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </template>
  </PageTemplate>
</template>

<script>
import Constants from "@/Constants";
import PageTemplate from "../../templates/PageTemplate";
import { mapActions } from "vuex";

export default {
  name: "UserProfile",
  components: { PageTemplate },
  created() {
    console.log("UserProfile Created");
    this.$store.dispatch("showProgressBar");
    this.savePasswordButton = false;
    this.getUserProfile();
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    comparePasswords() {
      if (this.changePasswordData.new_password !== this.new_password_again) {
        return this.$t("new_password_repeat_not_equal");
      }
    },
  },
  data() {
    return {
      userProfileData: {
        username: "",
        email: "",
        name: "",
        last_name: "",
        phone_number: "",
        tax_number: "",
        company_name: "",
      },
      changePasswordData: {
        current_password: "",
        new_password: "",
      },
      new_password_again: "",
      readOnly: true,
      disableEditButton: false,
      disableSaveButton: true,
      savePasswordButton: false,
      show1: false,
      show2: false,
      show3: false,
      passWordRules: [(v) => !!v || this.$t("missing_current_password")],
      newPassWordRules: [(v) => !!v || this.$t("missing_new_password")],
    };
  },
  methods: {
    savePassword() {
      if (this.$refs.form.validate()) {
        this.$http
          .put(Constants.apiMethod.changePassword, this.changePasswordData)
          .then((response) => {
            console.log(response.data);
          });
        this.afterChangePassword();
      }
    },
    afterChangePassword() {
      this.$router.push({ path: "/HighlightedProducts" });
    },
    editUserProfile() {
      this.readOnly = false;
      this.disableEditButton = true;
      this.disableSaveButton = false;
    },
    saveUserProfile() {
      this.$http
        .put(Constants.apiMethod.userProfile, this.userProfileData)
        .then((response) => {
          console.log(response.data);
        });
      this.disableSaveButton = true;
      this.disableEditButton = false;
      this.readOnly = true;
    },
    getUserProfile() {
      this.loadUserProfile(() => {
        this.userProfileData.username = this.$store.state.userProfile.username;
        this.userProfileData.email = this.$store.state.userProfile.email;
        this.userProfileData.name = this.$store.state.userProfile.name;
        this.userProfileData.last_name = this.$store.state.userProfile.lastName;
        this.userProfileData.phone_number = this.$store.state.userProfile.phoneNumber;
        this.userProfileData.tax_number = this.$store.state.userProfile.taxNumber;
        this.userProfileData.company_name = this.$store.state.userProfile.companyName;
        this.$store.dispatch("hideProgressBar");
      });
    },
    ...mapActions(["showProgressBar", "hideProgressBar", "loadUserProfile"]),
  },
};
</script>

<style scoped></style>
