<template>
    <div class="container-loader">
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
        <h1>{{ $t('searching') }}</h1>
    </div>
</template>
  
<script>
export default {
    name: "Wait",

};
</script>


<style>
.container-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.ring {
    width: 200px;
    height: 200px;
    border: 0px solid white;
    border-radius: 50%;
    position: absolute;
}


.ring:nth-child(1) {
    border-bottom-width: 8px;
    border-color: #00f2ff;
    animation: rotate1 1s linear infinite;
}

@keyframes rotate1 {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);

    }
}

.ring:nth-child(2) {
    border-bottom-width: 8px;
    border-color: #002586;
    animation: rotate2 1s linear infinite reverse;
}

@keyframes rotate2 {
    0% {
        transform: rotateX(35deg) rotateY(10deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(10deg) rotateZ(360deg);

    }
}

.ring:nth-child(3) {
    border-bottom-width: 8px;
    border-color: #0090f9;
    animation: rotate3 1s linear infinite;
}

@keyframes rotate3 {
    0% {
        transform: rotateX(35deg) rotateY(65deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(65deg) rotateZ(360deg);

    }
}
</style>
  