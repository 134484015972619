/* eslint-disable */
<template>
  <vue-apex-charts
    :height="my_height"
    :type="type"
    :options="options"
    :series="series"
  ></vue-apex-charts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "s-chart",
  props: ["data", "dark", "height"],
  components: {
    VueApexCharts,
  },
  computed: {
    type() {
      return this.data.type;
    },
    theme_mode() {
      return !this.dark ? "light" : "dark";
    },
    my_height() {
      return this.height || 260;
    },
    labels() {
      const result = [];
      this.data.series.forEach((element) => {
        result.push(element.name);
      });
      return result;
    },
    options() {
      const result = {
        theme: {
          mode: this.theme_mode,
          monochrome: {
            enabled: false,
          },
        },
        chart: {
          id: this.data.id,
        },
        legend: {
          position: "right",
        },
        dataLabels: {
          enabled: true,
        },
        labels: this.labels,
        title: {
          text: this.title,
        },
        colors: this.data.colors || [
          "#FEB019",
          "#008FFB",
          "#FF4560",
          "#00E396",
          "#775DD0",
        ],
      };
      if (this.data.fill) result.fill = this.data.fill;
      if (this.data.tooltip) result.tooltip = this.data.tooltip;
      return result;
    },
    series() {
      const result = [];
      this.data.series.forEach((element) => {
        result.push(element.values[0]);
      });
      return result;
    },
  },
};
</script>
