<template>
  <PageTemplate>
    <template slot="title">{{ this.$t("product") }}</template>
    <template slot="content">
      <v-row align="start" justify="start" class="ma-0">
        <product-group-view v-for="product in subProductGroups" :key="product.codePath"
          style="margin: 8px; max-width: 298px;" @click.native="subProductGroupsAction(product.codePath)">
          <template slot="productGroupsName">{{ product.name }}</template>
        </product-group-view>
      </v-row>
    </template>
    <template slot="content">
      <v-row align="start" justify="start" class="ma-0">
        <product-view v-for="product in products" :key="product.codePath" style="" :product="product">
        </product-view>
      </v-row>
    </template>

    <template slot="sortMenu">
      <v-menu bottom left>
       <template v-slot:activator="{ on }">
            <v-btn class="deskop" dark icon v-on="on">
              <img  src="../../../assets/Icon_up-down.svg" style="width: 1.6rem;">
            </v-btn>
            <!-- <button class="Mobil" v-on="on">
              SortMenu
            </button> -->
          </template>

        <v-list dense>
          <v-list-item @click="byNameASC()">
            <v-list-item-title>{{ this.$t("by_name_asc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byNameDESC()">
            <v-list-item-title>{{ this.$t("by_name_desc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byCodeASC()">
            <v-list-item-title>{{ this.$t("by_code_asc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byCodeDESC()">
            <v-list-item-title>{{ this.$t("by_code_desc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byGroup()">
            <v-list-item-title>{{ this.$t("by_group") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </PageTemplate>
</template>

<script>
import Constants from "@/Constants";
import PageTemplate from "../../templates/PageTemplate";
import ProductView from "../../partial/ProductView";
import ProductGroupView from "../../partial/ProductGroupView";
import { mapActions } from "vuex";
export default {
  name: "Product",
  components: { PageTemplate, ProductView, ProductGroupView },
  data() {
    return {
      products: {},
      subProductGroups: {},
      codePath: {},
    };
  },
  created() {
    this.showProgressBar();
    this.codePath = this.$route.params.codePath;
    this.getProducts();
    this.getSubProductGroups();
  },
  methods: {
    getProducts() {
      this.$http
        .get(
          Constants.apiMethod.products
            .concat(this.codePath)
            .concat("&includeRelatedObjects=1")
        )
        .then((response) => {
          this.products = response.data;
          this.hideProgressBar();
        });
    },
    getSubProductGroups() {
      this.$http
        .get(Constants.apiMethod.productGroups.concat(this.codePath))
        .then((response) => {
          this.subProductGroups = response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        });
        
    },
    subProductGroupsAction(item) {
      this.showProgressBar();
      this.$router.push({
        name: "SubProduct",
        params: { codePath: item },
      });
    },
    productAction(product) {
      this.$router.push({
        name: "ProductDetailPage",
        params: { product: product },
      });
      this.showProgressBar();
    },
    byNameASC() {
      this.products.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    byNameDESC() {
      this.products.sort((a, b) => (a.name < b.name ? 1 : -1));
    },
    byCodeASC() {
      this.products.sort((a, b) => (a.code > b.code ? 1 : -1));
    },
    byCodeDESC() {
      this.products.sort((a, b) => (a.code < b.code ? 1 : -1));
    },
    byGroup() {
      this.products.sort((a, b) => (a.code < b.code ? 1 : -1));
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>

<style scoped>
/* .bar{
  background-color: #F5F5F5;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.Mobil{
  display: none;
}

@media only screen and (max-width: 600px) {
  .deskop {
    display: none;
  }
  .Mobil{
    display: block;
    color: black !important;
    background: white !important;
    width: 100%;
    height: 100%;
  }
} */
</style>
