<template>
  <h1>Success</h1>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "Success",
  data() {
    return {
      value: {},
    };
  },
  created() {
    this.getPaymentToken();
  },
  methods: {
    getPaymentToken() {
      this.value = JSON.parse(localStorage.getItem("paymentToken"));
      this.value.paymentStatus = moment()
        .format("DD-MM-YYYY/HH:mm:ss")
        .concat(" -Payment Success");
      console.log(this.value);
      this.createLog();
    },
    createLog() {
      this.$store.dispatch("loadUserProfile", () => {
        console.log(this.$store.state.userProfile);
        console.log(this.$store.state.currentWholesaler);
        this.$store.state.currentWholesaler = this.$store.state.userProfile.currentWholesaler.name;
        this.$http
          .post(
            "http://payservicetest.akead.link/V0000/Set/beforePayment",
            this.value
          )
          .then((response) => {
            console.log(response.data);
            this.changeRoute();
          });
      });
    },
    changeRoute() {
      this.$store.state.success = true;
      this.$router.push({ name: "Basket" });
      console.log("asd");
    },
  },
};
</script>

<style scoped></style>
