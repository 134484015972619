<template>
    <transition name="modal">
            <div class="modal-mask">
              <div class="modal-wrapper">
                <div class="modal-container">

                  <div class="modal-header">
                    <slot name="header">
                    Detailed Search
                    </slot>
                    <hr>
                  </div>

                  <div class="modal-body">
                   <form >
                        <input type="text" placeholder="Product Code">
                        <input type="text" placeholder="Product Name">
                        <input type="text" >
                        <input type="text">
                        <input type="button" value="Search" @click="Search()" >
                   </form>
                  </div>
                </div>
              </div>
            </div>
          </transition>
</template>

<script>
    export default {
methods: {
    Search() {
              this.$store.state.detailedSearch = false;

    }        
    }
}

</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
hr {
    margin-top: 0rem;
    margin-bottom: 0rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 1rem 0;
}
.modal-body input {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 1rem;
}

.modal-default-button {
    float: right;
    border : 1px solid #ccc;
    color: #000;
    margin: 1rem;

}


</style>