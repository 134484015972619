<template>
  <page-template>
    <template slot="title">{{ "Payment" }}</template>
    <template slot="content">
      <v-col>
        <v-row justify="center">
          <vue-paycard :value-fields="valueFields"></vue-paycard>
        </v-row>
        <v-row justify="center" style="margin-top: -140px">
          <v-card height="500" width="650">
            <v-row style="margin: 65px"> </v-row>
            <v-row justify="center">
              <v-form class="ma-15" lazy-validation ref="form">
                <v-text-field label="CardHolder" v-model="name" />
                <v-text-field
                  label="CardNumber"
                  required
                  v-model="number"
                  maxlength="20"
                />
                <v-row align="center">
                  <v-col class="d-flex" cols="12" sm="4">
                    <v-select
                      v-model="valueFields.cardMonth"
                      :items="month"
                      label="Month"
                    />
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="4">
                    <v-select
                      v-model="valueFields.cardYear"
                      :items="year"
                      label="Year"
                    />
                  </v-col>
                  <v-col class="d-flex" cols="8" sm="4">
                    <v-text-field label="CVC" v-model="cvc" maxlength="3" />
                  </v-col>
                </v-row>
                <v-row align="center" justify="space-between">
                  <v-btn class="mr-4" color="secondary" width="200">
                    Reset Form
                  </v-btn>
                  <v-btn @click="submit()" color="accent" width="200">
                    Submit
                  </v-btn>
                </v-row>
              </v-form>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
      <v-col> </v-col>
    </template>
  </page-template>
</template>
<script>
import PageTemplate from "../../templates/PageTemplate";
import { VuePaycard } from "vue-paycard";
import moment from "moment-timezone";
export default {
  components: {
    PageTemplate,
    VuePaycard,
  },
  name: "Payment",
  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    beforePaymentLog: {
      paymentStatus: null,
      paymentToken: null,
    },
    menu: false,
    name: "",
    number: "",
    modal: false,
    cvc: "",
    i: 0,
    month: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
    year: [],
    valueFields: {
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvc: "",
      paymentToken: "",
      paymentStatus: "",
    },
  }),
  created() {
    this.valueFields.paymentToken = this.$store.state.paymentToken;
    this.beforePaymentLog.paymentToken = this.$store.state.paymentToken;
    this.beforePaymentLog.paymentStatus = moment()
      .format("DD-MM-YYYY/HH:mm:ss")
      .concat(" -Payment Page Created");
    this.createYear();
    this.createLog();
    // console.log(this.$router.currentRoute.query)
    console.log(this.$store.state.paymentToken);
  },
  watch: {
    name() {
      this.valueFields.cardName = this.name.toUpperCase();
      this.name = this.name.toLocaleUpperCase("tr-TR");
    },
    cvc() {
      this.valueFields.cardCvc = this.cvc;
      console.log(this.valueFields);
    },
    number() {
      if (this.number === "") {
        this.i = -1;
      }
      this.valueFields.cardNumber = this.number;
      if (this.i === 3) {
        this.number = this.number.concat(" ");
        this.i = -1;
      } else {
        this.i++;
      }

      this.valueFields.cardNumber = this.valueFields.cardNumber.replace(
        /\s/g,
        ""
      );
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    createLog() {
      this.$http
        .post(
          "http://payservicetest.akead.link/V0000/Set/beforePayment",
          this.beforePaymentLog
        )
        .then((response) => {
          console.log(response.data);
        });
    },
    submit() {
      this.$store.dispatch("showProgressBar");
      this.valueFields.paymentStatus = moment()
        .format("DD-MM-YYYY/HH:mm:ss")
        .concat(" -Payment Submit");

      this.$http
        .post(
          "http://payservicetest.akead.link/V0000/Perform/payment",
          this.valueFields
        )
        .then((response) => {
          this.$store.dispatch("showProgressBar");
          console.log(response.data);
          localStorage.setItem(
            "paymentToken",
            JSON.stringify(this.beforePaymentLog)
          );
          setTimeout(
            () => (window.location.href = response.data.UCD_URL),
            5000
          );
        });
      console.log(this.valueFields);
      this.$store.dispatch("hideProgressBar");
    },
    createYear() {
      var x = moment().format("YYYY");
      var a = parseInt(x);
      for (var i = 0; i < 11; i++) {
        this.year.push(a + i);
      }
    },
    save(date) {
      this.$refs.menu.save(date);
      this.valueFields.cardYear = date.substring(0, 4);
      this.valueFields.cardMonth = date.substring(5, 7);
      console.log(this.valueFields);
    },
  },
};
</script>

<style scoped></style>
