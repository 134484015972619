import Vue from "vue";
import VueI18n from "vue-i18n";
import tr from "./languageFiles/trLanguage";
import en from "./languageFiles/engLanguage";
import de from "./languageFiles/deLanguage";
import fr from "./languageFiles/frLanguage";

Vue.use(VueI18n);

const messages = {
  en: en,
  tr: tr,
  de: de,
  fr: fr,
};

const i18n = new VueI18n({
  locale: "tr", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});

export default i18n;
