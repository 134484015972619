<template>
  <v-app id="inspire">
    <v-progress-linear
        v-if="$store.state.progressBar.visibility"
        :indeterminate="$store.state.progressBar.animating"
        :color="$store.state.progressBar.color"
        absolute
        top
        style="z-index: 10"
    />
    <alert-dialog
        v-model="$store.state.alertDialog.visibility"
        :title="$store.state.alertDialog.title"
        :content="$store.state.alertDialog.message"
    />
    <confirm-dialog
        v-model="$store.state.confirmDialog.visibility"
        :title="$store.state.confirmDialog.title"
        :content="$store.state.confirmDialog.message"
        :confirmtext="$store.state.confirmDialog.confirmButtonText"
        :canceltext="$store.state.confirmDialog.cancelButtonText"
        :onconfirm="$store.state.confirmDialog.onConfirm"
    />
    <router-view/>
    <Modal v-if="this.$store.state.detailedSearch" />
  </v-app>
</template>

<script>
import AlertDialog from "@/components/partial/dialog/AlertDialog";
import ConfirmDialog from "@/components/partial/dialog/ConfirmDialog";
// import Constants from "@/Constants";
import i18n from "@/plugins/language/i18n";
import wholesalerConfig from "@/plugins/style/wholesalersConfig";
import Modal from "./components/Modal.vue";
export default {
  name: "App",
  components: {
    AlertDialog,
    ConfirmDialog,
    Modal
},
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || wholesalerConfig.loadMeta(window.location.host);
      },
    },

  },
  created() {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = wholesalerConfig.loadFavicon(window.location.host)
    if (this.$cookie.get("lang") !== null) {
      i18n.locale = this.$cookie.get("lang")
      // console.log(this.$cookie.get("lang"))
      if (this.$cookie.get("bgtoken") === null) {
        localStorage.setItem("proWebBasket", JSON.stringify([]));
        if (this.$router.currentRoute.fullPath !== "/Login") {
          this.$router.push({name: "Login"});
        }
      } else if (this.$cookie.get("bgtoken") !== null) {
        this.$store.dispatch("loadUserProfile", () => {
          if (this.$store.state.userProfile.currentWholesaler === null) {
            this.$router.push({name: "Wholesaler"})
          } else {
            this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
            this.$store.state.badgeCounter = this.proWebBasket.length;
            this.$store.dispatch("campaignColors")
              this.$store.state.menuRestriction = true
              this.$router.push({name: "HighlightedProducts"});

          }
        })

        // if (response.data.error_code === 1504) {
        //   this.$store.state.alertDialog.message = this.$t("process_fail_message") + response.data.error_code;
        //   this.$store.state.alertDialog.visibility = true;
        //   this.$refs.form.reset();
        // } else {
        //   this.$router.push({name: "HighlightedProducts"});
        //   this.$store.dispatch("loadUserProfile", () => {
        //     this.$store.state.currentWholesaler = this.$store.state.userProfile.currentWholesaler.name;
        //     this.$store.state.currentWholesaler = this.$store.state.userProfile.currentWholesaler.name;
        //     this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
        //     this.$store.state.badgeCounter = this.proWebBasket.length;
        //     this.$store.dispatch("campaignColors");
        //     this.$router.push({name: "HighlightedProducts"});
        //   })
        // }
      }


      // if (this.$router.currentRoute.path === "/") {
      // var route = this.$router.currentRoute.fullPath;
      // }
    }
    else{
      this.$cookie.set("lang", "en");
      this.$router.push({name: "Login"})
    }
  },
  data: () => ({
    proWebBasket: null
  }),
};
</script>
