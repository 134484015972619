<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="col-sm-5 col-md-5 col-lg-3 col-xl-3">
        <v-card elevation="5" style="border-radius: 5px" min-width="380"  >
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t("login") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                  prepend-icon="person"
                  name="username"
                  :rules="usernameRules"
                  v-model="loginData.username"
                  :label="$t('username')"
                  required
              />
              <v-text-field
                  prepend-icon="lock"
                  name="password"
                  id="password"
                  autocomplete="off"
                  :label="$t('password')"
                  :rules="passwordRules"
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="login"
                  v-model="loginData.password"
                  required
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="4">
                <v-btn
                    text
                    color="accent"
                    class="body-2"
                    @click="renewPasswordOnClick()"
                >{{ $t("forgot_password") }}
                </v-btn
                >
              </v-col>
              <v-col cols="8">
                <v-row justify="end" style="padding-right: 16px">
                  <v-btn
                      class="body-2"
                      color="accent"
                      style="margin-right: 4px"
                      @click="registerOnClick()"
                  >{{ $t("register") }}
                  </v-btn>
                  <v-btn
                      class="body-2"
                      color="accent"
                      :disabled="!valid"
                      @click="login()"
                  >{{ $t("login") }}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Constants from "@/Constants";
import i18n from "@/plugins/language/i18n";
import {mapActions} from "vuex";
import DeviceUUID from "device-uuid";

export default {
  name: "Login",
  created() {
    this.$store.dispatch("hideProgressBar");
    console.log("loginPage_Created");
  },
  data() {
    return {
      loginData: {
        device_universal_id: DeviceUUID.DeviceUUID().get(),
        device_name: DeviceUUID.DeviceUUID().parse().platform,
        device_type: "OTHER",
        password: null,
        username: null,
      },
      item: {user_wholesaler_id: "3170"},
      // item: {user_wholesaler_id: "740"},
      showPassword: false,
      usernameRules: [(v) => !!v || this.$t("missing_username_message")],
      passwordRules: [(v) => !!v || this.$t("missing_password_message")],
      valid: false,
    };
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("showProgressBar");
        this.$http
            .post(Constants.apiMethod.login, this.loginData)
            .then((response) => {
              console.log(response.data)
              if (response.data.access_token) {
                console.log("Access_Token: " + response.data.access_token);
                this.$cookie.set("bgtoken", response.data.access_token, 1); // 1 days
                this.$cookie.set("lang", i18n.locale); // 5 days
                this.$store.dispatch("loadUserProfile",()=>{
                  this.$router.push({name: "Wholesaler"})
                })
              } else {
                this.$store.state.alertDialog.message = response.data.message;
                this.$store.state.alertDialog.visibility = true;
                this.$refs.form.reset();
              }
            },(err)=> {
              console.log(err)
              if(err.data.error_code === 5){
                this.$router.push({ name: "Login" });
              }
            });
      }
    },
    renewPasswordOnClick() {
      this.$store.dispatch("showProgressBar");
      this.$router.push({name: "RenewPassword"});
    },
    registerOnClick() {
      this.$store.dispatch("showProgressBar");
      this.$router.push({name: "Register"});
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>
<style scoped>

</style>