<template>
  <PageTemplate>
    <template slot="title">{{ this.$t("settings") }}</template>
    <template slot="content">
      <v-layout row wrap align-center style="margin: 5px;">
        <v-flex xs6>
          <v-subheader style="color: black;"
            >{{ this.$t("application_language") }}:</v-subheader
          >
        </v-flex>

        <v-flex xs6>
          <v-select
            v-model="select"
            :items="items"
            item-text="state"
            :label="$t('select_application_language')"
            persistent-hint
            return-object
            single-line
            @change="changeLanguage"
          />
        </v-flex>
      </v-layout>
    </template>
  </PageTemplate>
</template>

<script>
import { mapActions } from "vuex";
import PageTemplate from "../../templates/PageTemplate";
import i18n from "@/plugins/language/i18n";
export default {
  name: "Settings",
  components: { PageTemplate },
  data() {
    return {
      language: "",
      select: { state: "English" },
      items: [
        { state: "English" },
        { state: "Deutcsch" },
        { state: "Français" },
        { state: "Türkçe" },
      ],
    };
  },
  created() {
    this.hideProgressBar();
    this.getLanguageFromCookie();
  },
  methods: {
    changeLanguage(selectObj) {
      console.log(selectObj.state);
      switch (selectObj.state) {
        case "Français":
          this.language = "fr";
          break;
        case "English":
          this.language = "en";
          break;
        case "Deutcsch":
          this.language = "de";
          break;
        case "Türkçe":
          this.language = "tr";
          break;
      }
      i18n.locale = this.language;
      this.$cookie.set("lang", this.language, 365);
    },
    getLanguageFromCookie() {
      this.language = this.$cookie.get("lang");
      console.log(this.language);
      switch (this.language) {
        case "fr":
          this.select.state = "Français";
          break;
        case "en":
          this.select.state = "English";
          break;
        case "de":
          this.select.state = "Deutcsch";
          break;
        case "tr":
          this.select.state = "Türkçe";
          break;
      }
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>
