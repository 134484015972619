<template>
  <v-container fill-height fluid style="background: #fdf8f8">
    <router-view />

    <!--    <v-img-->
    <!--      :src="require('../../assets/AKEAD_Logo_new_.svg')"-->
    <!--      style="position: absolute; left: 20px; top: 15px; padding: 3px;"-->
    <!--    />-->
    <div
      style="
        position: absolute;
        right: 20px;
        top: 15px;
        background-color: rgba(148, 164, 199, 0.35);
        border-radius: 25px;
        padding: 3px;
      "
    >
      <button
        v-for="entry in languages"
        :key="entry.language"
        @click="changeLocale(entry.language)"
      >
        <flag
          :iso="entry.flag"
          style="margin: 4px; border-radius: 25px; font-size: 20px;"
        />
      </button>
    </div>
  </v-container>
</template>
<script>
import i18n from "@/plugins/language/i18n";
export default {
  name: "BlankLayout",
  data() {
    return {
      languages: [
        { flag: "tr", language: "tr" },
        { flag: "gb", language: "en" },
        { flag: "de", language: "de" },
        { flag: "fr", language: "fr" },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
      this.$cookie.set("lang", locale, 365);
      // this.$refs.form.validate().clean;
      // this.$refs.form.reset();
    },
  },
};
</script>
