<template>
  <page-template @changeToggle="toggle = $event">
    <template slot="title">{{ this.$t("search_results") }}</template>
    <template slot="content">
      <v-layout>
        <v-layout align-center justify-start row wrap style="margin: 0;" v-if="!this.toggle" v-show="!noData">
          <ProductListItem v-for="product in $store.state.productSearchConstants
            .productSearchResult" :key="product.id" style="margin: 0px;" :product="product" />

        </v-layout>
        <v-layout align-center justify-start row wrap style="margin: 0;" v-if="this.toggle" v-show="!noData">
          <product-view v-for="product in $store.state.productSearchConstants
            .productSearchResult" :key="product.id" style="margin: 0px;" :product="product" />

        </v-layout>
        <Wait v-show="noData" />
      </v-layout>
    </template>
  </page-template>
</template>

<script>
import PageTemplate from "../../templates/PageTemplate";
import ProductView from "../../partial/ProductView";
import ProductListItem from "@/components/partial/ProductListItem";
import { mapActions } from "vuex";
import Wait from "@/components/pages/scada/Wait";



export default {
  name: "SearchProduct",
  components: { PageTemplate, ProductView, ProductListItem, Wait },
  data() {
    return {
      products: {},
      noData: true,
      toggle: this.$store.state.userProfile.currentWholesaler.settings.defaultProductListType == 2 ? true : false,

    };
  },
  created() {
    if (this.$store.state.productSearchConstants.productSearchResult.length == 0) {
      this.noData = true;
    }
    this.$store.dispatch("searchProduct");
    setTimeout(() => {
      if (this.$store.state.productSearchConstants.productSearchResult.length > 0) {
        this.noData = false;
      }
    }, 3000)


  },
  ...mapActions(["showProgressBar", "hideProgressBar",]),


};
</script>

<style scoped></style>
