<template>
  <PageTemplate>
    <template slot="title">{{ this.$t("dashboard") }}</template>
    <template slot="content">
      <h3>deviceUUID : {{ deviceUUID }}</h3>
      <h3>isSmart? : {{ smartTv }}</h3>
      <h3>browser : {{ browser }}</h3>
      <h3>browser : {{ platform }}</h3>
      <v-row class="ma-0">
        <v-col
          class="pa-1 ma-0"
          v-for="(chart, index) in chartData"
          :key="index"
          :cols="chart.width_rate"
          v-show="chart.disabled"
        >
          <v-card elevation="3">
            <iqv-chart :data="chart" />
          </v-card>
        </v-col>
        <!--        <v-col :cols="firstChart.width_rate">-->
        <!--          <v-card>-->
        <!--            <iqv-chart :data="firstChart" />-->
        <!--          </v-card>-->
        <!--        </v-col>-->
        <!--        <v-col cols="4">-->
        <!--          <v-card>-->
        <!--            <iqv-chart :data="thirdChart" />-->
        <!--          </v-card>-->
        <!--        </v-col>-->
        <!--        <v-col cols="4">-->
        <!--          <v-card>-->
        <!--            <iqv-chart :data="thirdChart" />-->
        <!--          </v-card>-->
        <!--        </v-col>-->
        <!--        <v-col :cols="secondChart.width_rate">-->
        <!--          <v-card>-->
        <!--            <iqv-chart :data="secondChart" />-->
        <!--          </v-card>-->
        <!--        </v-col>-->

        <!--        <v-col cols="4">-->
        <!--          <v-card>-->
        <!--            <iqv-chart :data="secondChart" />-->
        <!--          </v-card>-->
        <!--        </v-col>-->
        <!--        <v-col cols="8">-->
        <!--          <v-card>-->
        <!--            <iqv-chart :data="chartData[4]" />-->
        <!--          </v-card>-->
        <!--        </v-col>-->
      </v-row>
      <!--      <v-row align="center" justify="center" style="margin: -4px;">-->
      <!--        <v-col-->
      <!--          v-for="(chart, index) in chartData"-->
      <!--          :key="index"-->
      <!--          :cols="`${6 * Math.max(Math.min(2, chart.width_rate), 1)}`"-->
      <!--          :sm="`${4 * Math.max(Math.min(3, chart.width_rate), 1)}`"-->
      <!--          :md="`${3 * Math.max(Math.min(4, chart.width_rate), 1)}`"-->
      <!--          :lg="`${2 * Math.max(Math.min(6, chart.width_rate), 1)}`"-->
      <!--          :xl="`${Math.max(Math.min(12, chart.width_rate), 1)}`"-->
      <!--          class="pa-0"-->
      <!--        >-->
      <!--          <v-card class="ma-2">-->
      <!--            <iqv-chart :data="chart" :dark="$vuetify.theme.isDark" />-->
      <!--          </v-card>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <v-navigation-drawer
        v-model="$store.state.buttonTry"
        right
        app
        width="300"
        class="elevation-5"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title ma-2">
              Dashboard Settings
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider> </v-divider>

        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in chartData" :key="i">
            <v-expansion-panel-header>{{
              item.title
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col :cols="11" class="pa-0">
                <v-icon style="margin-right: -20px;">info_outlined</v-icon>
                {{ item.chartDescription }}
              </v-col>
              <v-col :cols="11" class="pa-0">
                <v-switch
                  class="pa-0"
                  v-model="item.disabled"
                  color="accent"
                  label="Enable/Disable Chart"
                />
              </v-col>
              <v-col :cols="11" class="pa-0">
                <v-select
                  v-model="item.width_rate"
                  :items="items"
                  class="pa-0"
                  label="Aspect ratio width:"
                />
              </v-col>
              <v-col :cols="11" class="pa-0">
                <v-select
                  v-model="item.type"
                  :items="type"
                  class="pa-0"
                  :label="item.type"
                />
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-navigation-drawer>
    </template>
  </PageTemplate>
</template>

<script>
import chartData from "../../../plugins/chart/src/sample/chart_data";
import IqvChart from "../../../plugins/chart/src/IqvChart";
import PageTemplate from "../../templates/PageTemplate";
import DeviceUUID from "device-uuid";
export default {
  name: "Dashboard",
  components: { PageTemplate, IqvChart },
  data() {
    return {
      select: 2,
      chart: "Bar",
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      type: ["pie", "line", "bar", "donut", "scatter"],
      checkbox: true,
      mandatory: true,
      firstChart: null,
      secondChart: null,
      thirdChart: null,
      navigator: true,
      deviceUUID: "",
      browser: "",
      smartTv: "smart değil",
      chartData: null,
      platform: "",
    };
  },
  created() {
    console.log(DeviceUUID.DeviceUUID().parse());
    console.log(DeviceUUID.DeviceUUID().get());
    console.log(DeviceUUID.DeviceUUID().get().browser);
    this.deviceUUID = DeviceUUID.DeviceUUID().get();
    this.browser = DeviceUUID.DeviceUUID().parse().browser;
    this.platform = DeviceUUID.DeviceUUID().parse().platform;
    if (DeviceUUID.DeviceUUID().get().isSmartTV) {
      this.smartTv = "smart tv";
    }
    // this.firstChart = chartData[0];
    this.chartData = chartData;
    this.$store.state.chartData = this.chartData;
    console.log(chartData);
    // this.$store.state.progressBar.visibility = true;
    this.$store.state.dashboardRestriction = true;
    this.$store.dispatch("hideProgressBar");
    // this.secondChart = chartData[1];
    // this.thirdChart = chartData[4];
  },
  mounted() {},
  methods: {},
};
</script>
<style></style>
