import Vue from "vue";
import Vuex from "vuex";
import Constants from "@/Constants";
import router from '../router'
Vue.use(Vuex);

const state = {
  detailedSearch: false,
  progressBar: {
    visibility: false,
    color: "accent",
    animating: true,
  },
  alertDialog: {
    visibility: false,
    title: null,
    message: null,
  },
  confirmDialog: {
    visibility: false,
    title: "",
    message: "",
    confirmButtonText: "Yes",
    cancelButtonText: "Cancel",
    onConfirm: null,
  },
  productSearchConstants: {
    productSearchText: "",
    productSearchResult: [],
  },
  menuRestriction: false,
  paymentToken: "",
  chartData: null,
  campaignColors: null,
  dashboardRestriction: false,
  badgeCounter: 0,
  userProfile: {},
  currentWholesaler: null,
  buttonTry: true,
  navigationDrawer: false,
  navigationMini: false,
  token: "",
  encryptKey: "=?AKEAD?Pro?Web?=",
  success: false,
};

const getters = {
  getUserProfile() {
    return state.userProfile;
  },
};

const mutations = {
  SET_PROGRESS_STATE(_state, visibility) {
    state.progressBar.visibility = visibility;
  },
  SET_USER_PROFILE_STATE(_state, userProfile) {
    state.userProfile = userProfile;
    // state.currentWholesaler = userProfile.currentWholesaler.name;
    // console.log(state.userProfile)
  },
  SET_SEARCH_PRODUCT_STATE(_state, products) {
    state.productSearchConstants.productSearchResult = products;
  },
  SET_CAMPAIGN_COLORS(_state, campaignColors) {
    state.campaignColors = campaignColors;
  },
};

const actions = {
  showProgressBar({ commit }) {
    commit("SET_PROGRESS_STATE", true);
  },
  hideProgressBar({ commit }) {
    commit("SET_PROGRESS_STATE", false);
  },
  oneByOneList({ commit },list) { 
    commit("SET_ONE_BY_ONE_LIST",);
    const items = list.split(" / ");
    console.log(items);

  },
  loadUserProfile({ commit }, callback) {
    Vue.http.get(Constants.apiMethod.userProfile).then((response) => {
      console.log(response.data);
      commit("SET_USER_PROFILE_STATE", response.data);
      callback();
    }, (err) => {
      console.log(err)
      router.push({ name: "Login" })
    });
  },
  searchProduct({ commit, dispatch }) {
    Vue.http
      .get(
        Constants.apiMethod.searchPanel
          .concat(state.productSearchConstants.productSearchText)
          .concat("&includeRelatedObjects=1")
      )
      .then((response) => {
        commit("SET_SEARCH_PRODUCT_STATE", response.data);
        
        dispatch("hideProgressBar")
      });
    //state.productSearchConstants.productSearchText = null;
  },
  campaignColors({ commit }) {
    Vue.http.get(Constants.apiMethod.campaignColors).then((response) => {
      commit("SET_CAMPAIGN_COLORS", response.data);
      console.log(response.data)
    });
  },
  routerGoBack({ dispatch }) {
    dispatch("showProgressBar")
    setTimeout(function () { router.go(-1) }, 1500);
  }
};

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});

export default store;
