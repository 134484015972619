<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <PageTemplate>
    <template slot="title">{{ this.$t("basket") }}</template>
    <template slot="content">
      <div style="margin: 8px">
        <v-data-table :headers="headers" :items="proWebBasket" :no-data-text="$t('no_item_in_basket')"
          :loading-text="$t('loading').concat('...')" hide-default-footer class="elevation-10"
          @click:row="basketItemAction">
          <template #item.quantity="{ item }">{{ item.quantity }} {{ item.unit }}
          </template>
          <template v-slot:item.totalPriceValue="{ item }">
            {{ fixed(item.totalPriceValue) }}
            {{
              $store.state.userProfile.currentWholesaler.settings.currencyCode
            }}
          </template>
        </v-data-table>
        <v-row style="margin: 3px"> </v-row>

        <v-card elevation="4" class="pa-4">
          <v-row>
            <v-col cols="12" sm="3">
              <v-textarea auto-grow label="Order Note" rows="1" row-height="30" v-model="note" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-row>
                <v-subheader>{{ this.$t("total_quantity") }}:</v-subheader>
                <v-subheader>{{ quantity }}</v-subheader>
              </v-row>
            </v-col>

            <v-col cols="4">
              <v-layout align-center justify-center fill-height> </v-layout>
            </v-col>

            <v-col>
              <v-row>
                <v-layout align-end column>
                  <v-layout row>
                    <v-subheader>{{ this.$t("total_without_tax") }}:</v-subheader>
                  </v-layout>
                  <v-layout row>
                    <v-subheader>{{ this.$t("total_tax") }}:</v-subheader>
                  </v-layout>
                  <v-layout row>
                    <v-subheader>{{ this.$t("total") }}:</v-subheader>
                  </v-layout>
                </v-layout>
                <v-layout align-center column>
                  <v-layout row>
                    <v-subheader>{{
                      totalWithoutTax.toFixed(
                        this.$store.state.userProfile.currentWholesaler
                          .settings.priceRoundingDecimalCount
                      )
                    }}
                      {{
                        $store.state.userProfile.currentWholesaler.settings
                          .currencyCode
                      }}
                    </v-subheader>
                  </v-layout>
                  <v-layout row>
                    <v-subheader>{{
                      totalTax.toFixed(
                        this.$store.state.userProfile.currentWholesaler
                          .settings.priceRoundingDecimalCount
                      )
                    }}
                      {{
                        $store.state.userProfile.currentWholesaler.settings
                          .currencyCode
                      }}
                    </v-subheader>
                  </v-layout>
                  <v-layout row>
                    <v-subheader>{{
                      total.toFixed(
                        this.$store.state.userProfile.currentWholesaler
                          .settings.priceRoundingDecimalCount
                      )
                    }}
                      {{
                        $store.state.userProfile.currentWholesaler.settings
                          .currencyCode
                      }}
                    </v-subheader>
                  </v-layout>
                </v-layout>
              </v-row>
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-row justify="center" align="center">
              <v-btn :disabled="buttonDisabled" color="accent" @click="createOrder()">{{ this.$t("create_order")
              }}</v-btn>
              <div class="ma-8"></div>
              <!--              <v-btn color="#F57C00" @click="payment()">{{ "Payment" }}</v-btn>-->
            </v-row>
          </v-col>
        </v-card>

        <v-dialog v-model="dialog" width="550" style="margin: 0">
          <!--          <v-overlay>-->

          <v-card style="">
            <v-card-title class="body-1" primary-title>{{ dialogProduct.product.name }}
            </v-card-title>
            <v-progress-linear indeterminate color="accent" absolute top v-show="dialogProgress" />
            <v-card-title style="padding: 0">
              <v-layout>
                <v-flex>
                  <v-subheader style="color: black">{{
                    this.$t("quantity")
                  }}</v-subheader>
                </v-flex>
              </v-layout>
              <v-layout justify-center style="margin-left: -70px">
                <v-flex xs4>
                  <v-text-field type="number" style="text-align-last: center; color: black " :max="this.dialogProduct.product.stockPackageQuantity" v-model="dialogProductQuantity" v-if="!$store.state.userProfile.currentWholesaler.settings.sellStocklessProducts" />
                    <v-text-field type="number" style="text-align-last: center; color: black "  v-model="dialogProductQuantity" v-if="$store.state.userProfile.currentWholesaler.settings.sellStocklessProducts" />
                </v-flex>
              </v-layout>
              <v-layout>
                <v-radio-group v-model="row" :value="row" row :mandatory="false">
                  <v-radio color="#F57C00" label="AD" value="Ad"> </v-radio>
                  <v-radio v-show="dialogProduct.product.packageUnit !== dialogProduct.product.unit" color="#F57C00"
                    label="KOL" value="KOL">
                  </v-radio>
                </v-radio-group>
              </v-layout>
            </v-card-title>
            <v-divider />
            <v-card-title>
              <v-layout column>
                <v-layout row v-show="rowAction">
                  <v-flex xs5>
                    <v-subheader style="color: black">{{
                      this.$t("package_content")
                    }}</v-subheader>
                  </v-flex>
                  <v-flex xs5>
                    <v-subheader style="color: black">{{ dialogProduct.product.packagingQuantity }}
                    </v-subheader>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs5>
                    <v-subheader style="color: black">{{
                      this.$t("unit_price")
                    }}</v-subheader>
                  </v-flex>
                  <v-flex xs5>
                    <v-subheader style="color: black">{{ dialogProduct.currentMainUnitPriceDisplay.toFixed(2) }}
                    </v-subheader>
                  </v-flex>
                </v-layout>
                <v-layout row v-show="rowAction">
                  <v-flex xs5>
                    <v-subheader style="color: black">{{
                      this.$t("package_price")
                    }}</v-subheader>
                  </v-flex>
                  <v-flex xs5>
                    <v-subheader style="color: black">{{ dialogProduct.currentPackageUnitPriceDisplay.toFixed(2) }}
                    </v-subheader>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs5>
                    <v-subheader style="color: black">{{
                      this.$t("total_price")
                    }}</v-subheader>
                  </v-flex>
                  <v-flex xs5>
                    <v-subheader style="color: black">{{
                      dialogProduct.totalPriceValue
                    }}</v-subheader>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-card-title>

            <v-card-actions>
              <v-btn color="red" class="body-2" text @click="dialogRemove()">{{
                this.$t("remove")
              }}</v-btn>
              <v-spacer />
              <v-btn color="primary" class="body-2" text @click="dialogCancel()">{{ this.$t("cancel") }}</v-btn>
              <v-btn color="accent" class="body-2" @click="addBasket()">{{
                this.$t("ok")
              }}</v-btn>
            </v-card-actions>
          </v-card>
          <!--          </v-overlay>-->
        </v-dialog>
      </div>
    </template>
  </PageTemplate>
</template>

<script>
import { mapActions } from "vuex";
import Constants from "@/Constants";
import PageTemplate from "../../templates/PageTemplate";
import moment from "moment-timezone";
import style from "@/plugins/style/wholesalersConfig";

export default {
  name: "BasketPage",
  components: { PageTemplate },
  data() {
    return {
      initialize: {
        amount: "",
        currencyCode: this.$store.state.userProfile.currentWholesaler.settings
          .currencyCode,
        customerName: this.$store.state.userProfile.username,
        referenceNo: moment().format("DD-MM-YYYY/HH:mm"),
        sallerId: this.$store.state.userProfile.currentWholesaler.id,
      },
      note: "",
      proWebBasket: [],
      dialogProgress: false,
      getBasketProduct: Object,
      dialog: false,
      buttonDisabled: false,
      headers: [
        {
          text: this.$t("code"),
          value: "product.code",
          align: "center",
          sortable: false,
          width: "12%"
        },
        {
          text: this.$t("product"),
          value: "product.name",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("quantity"),
          value: "quantity",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("total"),
          value: "totalPriceValue",
          align: "center",
          sortable: false,
        },
      ],
      totalWithoutTax: 0,
      totalTax: 0,
      total: 0,
      quantity: 0,
      dialogProductQuantity: 0,
      row: "KOL",
      rowAction: true,
      oldProduct: {},
      dialogProduct: {
        totalPriceValue: "",
        currentMainUnitPriceDisplay: 0,
        currentPackageUnitPriceDisplay: 0,
        product: {
          packagingQuantity: null,
        },
      },
      basketItems: [],
      order: {
        productId: null,
        priceId: null,
        quantity: null,
        unit: null,
      },
    };
  },
  watch: {
    dialogProductQuantity() {
      return this.generateBasketItemAction();
    },
    row() {
      this.rowAction = !this.rowAction;
      return this.generateBasketItemAction();
    },
  },
  created() {
    this.showProgressBar();
    // this.$vuetify.theme.themes.light = '#3f51b5'
    style.loadStyle("deneme")
    if (this.$store.state.success === true) {
      this.createOrder();
    } else {
      // window.alert("ödeme alınamadı");
    }

    console.log(this.$store.state.paymentToken);
    if (
      localStorage.getItem("proWebBasket") == null ||
      localStorage.getItem("proWebBasket") === ""
    ) {
      localStorage.setItem("proWebBasket", JSON.stringify([]));
    }
    this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
    this.$store.state.badgeCounter = this.proWebBasket.length;
    this.calculateBasket();
  },
  methods: {
    payment() {
      this.$store.dispatch("showProgressBar");
      this.$http
        .post(
          "http://payservicetest.akead.link/V0000/set/initialize",
          this.initialize
        )
        .then(
          (response) =>
            (this.$store.state.paymentToken = response.data.paymentToken)
        )
        .then(() => {
          console.log(this.$store.state.paymentToken);
          this.$store.dispatch("hideProgressBar");
        })
        .then(() => {
          this.$router.push({ name: "Payment" });
        });
    },
    fixed(value) {
      return parseFloat(value).toFixed(2);
    },
    dialogRemove() {
      this.index = this.proWebBasket.findIndex(
        (s) => s.product.code === this.dialogProduct.product.code
      );
      this.proWebBasket.splice(this.index, 1);
      this.$store.state.badgeCounter--;
      localStorage.setItem("proWebBasket", JSON.stringify(this.proWebBasket));
      this.dialog = false;
      this.calculateBasket();
    },
    dialogCancel() {
      this.dialogProductQuantity = this.oldProduct.quantity;
      this.dialog = false;
    },
    addBasket() {
      this.showProgressBar();
      console.log(this.proWebBasket);
      if (
        this.proWebBasket.find(
          (s) => s.product.code === this.dialogProduct.product.code
        )
      ) {
        if (this.quantity > this.dialogProduct.product.stockPackageQuantity && this.$store.state.userProfile.currentWholesaler.settings.sellStocklessProducts == false) {
          this.quantity = this.dialogProduct.product.stockPackageQuantity
          return;
        }
        console.log(
          this.proWebBasket.find(
            (s) => s.product.code === this.dialogProduct.product.code
          )
        );
        this.index = this.proWebBasket.findIndex(
          (s) => s.product.code === this.dialogProduct.product.code
        );
        console.log(
          this.proWebBasket.findIndex(
            (s) => s.product.code === this.dialogProduct.product.code
          )
        );
        this.proWebBasket[this.index] = this.dialogProduct;
        console.log(this.proWebBasket);
        localStorage.setItem("proWebBasket", JSON.stringify(this.proWebBasket));
      }
      this.dialog = false;
      this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
      this.calculateBasket();
      this.hideProgressBar();
    },
    generateBasketItemAction() {
      this.dialogProgress = true;
      this.$http
        .get(
          Constants.apiMethod.generateBasketItem
            .concat("productId=")
            .concat(this.dialogProduct.product.id)
            .concat("&quantity=")
            .concat(this.dialogProductQuantity)
            .concat("&unit=")
            .concat(this.dialogProduct.product.packageUnit)
        )
        .then((response) => {
          this.dialogProduct = response.data;
          console.log(this.dialogProduct);
          this.dialogProgress = false;
          // return this.basketItem
        });
    },
    basketItemAction(item) {
      console.log(item);
      // this.showProgressBar()
      this.oldProduct = item;
      this.dialogProduct = item;
      console.log(this.dialogProduct.product.stockPackageQuantity);
      this.dialogProductQuantity = this.dialogProduct.quantity;
      this.row = item.unit;
      this.dialog = true;
    },
    calculateBasket() {
      // console.log(this.proWebBasket.length)
      this.quantity = 0;
      this.totalTax = 0;
      this.total = 0;
      this.totalWithoutTax = 0;

      for (var i = 0; i < this.proWebBasket.length; i++) {
        this.totalWithoutTax = this.proWebBasket[i].totalPriceValue + this.totalWithoutTax;
        this.totalTax = this.proWebBasket[i].price.valueWithTax * this.proWebBasket[i].quantity * this.proWebBasket[i].product.packagingQuantity - this.proWebBasket[i].totalPriceValue + this.totalTax;
        this.total = this.totalWithoutTax + this.totalTax;
        this.quantity = this.proWebBasket[i].quantity + this.quantity;
      }
      this.initialize.amount = this.totalWithoutTax;
      this.hideProgressBar();
    },
    createOrder() {
      this.showProgressBar();
      for (var i = 0; i < this.proWebBasket.length; i++) {
        this.order.productId = this.proWebBasket[i].product.id;
        this.order.priceId = this.proWebBasket[i].price.id;
        this.order.quantity = this.proWebBasket[i].quantity;
        this.order.unit = this.proWebBasket[i].unit;
        this.basketItems.push(this.order);
        this.order = {};
      }
      console.log(this.basketItems);
      this.$http
        .post(Constants.apiMethod.createOrder, {
          basketItems: this.basketItems,
          note: this.note,
        })
        .then(
          (response) => {
            console.log(response.data);
            if (response.data.success === "true") {
              this.proWebBasket = [];
              this.$store.state.badgeCounter = 0;
              localStorage.clear();
              this.totalWithoutTax = 0;
              this.totalTax = 0;
              this.total = 0;
              this.quantity = 0;
            }
          },
          (response) => {
            console.log(response);
            console.log("CREATE ORDER ERROR");
          }
        );
      localStorage.clear();
      this.calculateBasket();
      this.note = "";
      this.buttonDisabled = true;
      this.hideProgressBar();
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>

<style scoped>
.v-subheader {
  color: black;
}
</style>
