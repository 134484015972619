import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import Login from "./components/pages/auth/Login";
import Register from "./components/pages/auth/Register";
import BlankLayout from "./components/layouts/BlankLayout";
import MainLayout from "./components/layouts/MainLayout";
import UserProfile from "./components/pages/member/UserProfile";
import RenewPassword from "./components/pages/auth/RenewPassword";
import Settings from "./components/pages/settings/Settings";
import HighlightedProducts from "./components/pages/trade/HighlightedProducts";
import FavoriteProducts from "./components/pages/trade/FavoriteProducts";
import Invoice from "./components/pages/trade/Invoice";
import Order from "./components/pages/trade/Order";
import Documents from "./components/pages/trade/Documents";
import LastPurchasedProducts from "./components/pages/trade/LastPurchasedProducts";
import ProductGroups from "./components/pages/trade/ProductGroups";
import Product from "./components/pages/trade/Product";
import SubProduct from "./components/pages/trade/SubProduct";
import Basket from "./components/pages/trade/Basket";
import Wholesaler from "@/components/pages/wholesaler/Wholesaler";
import ProductDetail from "./components/pages/trade/ProductDetail";
import SearchProduct from "./components/pages/trade/SearchProduct";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Payment from "./components/pages/payment/Payment";
import Success from "./components/pages/payment/Success";
import Fail from "./components/pages/payment/Fail";
import Splash from "@/components/pages/auth/Splash";
import AccountActivities from "@/components/pages/trade/AccountActivities"
// import i18n from "@/plugins/language/i18n";
export default new Router({
  routes: [
    {
      path: "/Splash",
      name: "Splash",
      component: Splash,
      // meta: { title: 'BG Design' }
    },
    {
      path: "/",
      component: BlankLayout,
      children: [
        {
          path: "/Login",
          name: "Login",
          component: Login,
          // meta: { title: 'BG Design' }
        },
        {
          path: "/Register",
          name: "Register",
          component: Register,
        },
        {
          path: "/RenewPassword",
          name: "RenewPassword",
          component: RenewPassword,
        },
      ],
    },
    {
      path: "/",
      component: MainLayout,
      children: [
        {
          path: "/SearchProduct/:productSearch",
          name: "SearchProduct",
          component: SearchProduct,
        },
        {
          path: "/ProductDetail/:id",
          name: "ProductDetail",
          component: ProductDetail,
        },
        {
          path: "/UserProfile",
          name: "UserProfile",
          component: UserProfile,
        },
        {
          path: "/Wholesaler",
          name: "Wholesaler",
          component: Wholesaler,
        },
        {
          path: "/Settings",
          name: "Settings",
          component: Settings,
        },
        {
          path: "/Dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "/HighlightedProducts",
          name: "HighlightedProducts",
          component: HighlightedProducts,
        },
        {
          path: "/FavoriteProducts",
          name: "FavoriteProducts",
          component: FavoriteProducts,
        },
        {
          path: "/LastPurchasedProducts",
          name: "LastPurchasedProducts",
          component: LastPurchasedProducts,
        },
        {
          path: "/Documents",
          name: "Documents",
          component: Documents,
        },
        {
          path: "/AccountActivities",
          name: "AccountActivities",
          component: AccountActivities,
        },
        {
          path: "/Order/:order",
          name: "Order",
          component: Order,
        },
        {
          path: "/Invoice/:invoice",
          name: "Invoice",
          component: Invoice,
        },
        {
          path: "/ProductGroups",
          name: "ProductGroups",
          component: ProductGroups,
        },
        {
          path: "/Product/:codePath",
          name: "Product",
          component: Product,
        },
        {
          path: "/SubProduct/:codePath",
          name: "SubProduct",
          component: SubProduct,
        },
        {
          path: "/Basket",
          name: "Basket",
          component: Basket,
        },
        {
          path: "/Payment",
          name: "Payment",
          component: Payment,
        },
        {
          path: "/Success",
          name: "Success",
          component: Success,
        },
        {
          path: "/Fail",
          name: "Fail",
          component: Fail,
        },
      ],
    },
  ],
  mode: "history",
});
