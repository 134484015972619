/* eslint-disable */
<template>
  <vue-apex-charts
    :height="my_height"
    :type="type"
    :options="options"
    :series="series"
  ></vue-apex-charts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "psv-chart",
  props: ["data", "dark", "height"],
  components: {
    VueApexCharts,
  },
  computed: {
    type() {
      return this.data.type;
    },
    is_bar() {
      return this.type === "bar";
    },
    is_line() {
      return this.type === "donut";
    },
    is_area() {
      return this.type === "area";
    },
    is_mixed() {
      return this.type === "mixed";
    },
    theme_mode() {
      return !this.dark ? "light" : "dark";
    },
    my_height() {
      return this.height || 248;
    },
    categories() {
      return this.data.x_axis;
    },
    is_horizontal() {
      return !this.data.is_horizontal ? false : this.data.is_horizontal;
    },
    title() {
      return this.data.title;
    },
    options() {
      const result = {
        theme: {
          mode: this.theme_mode,
        },
        chart: {
          id: this.data.id,
          stacked: !this.data.is_stacked ? false : this.data.is_stacked,
          stackType: this.data.is_stacked ? this.data.stack_type : null,
        },
        // title: {
        // text: this.title
        // },
        plotOptions: {
          bar: {
            horizontal: this.is_horizontal,
          },
        },
        xaxis: {
          categories: this.categories,
        },
        labels: this.categories ? this.categories : undefined,
        colors: this.data.colors || [
          "#FEB019",
          "#008FFB",
          "#FF4560",
          "#00E396",
          "#775DD0",
        ],
      };
      if (this.data.dataLabels) result.dataLabels = this.data.dataLabels;
      if (this.data.fill) result.fill = this.data.fill;
      if (this.data.tooltip) result.tooltip = this.data.tooltip;
      if (this.data.xaxis_labels) result.xaxis.labels = this.data.xaxis_labels;
      return result;
    },
    series() {
      const result = [];
      this.data.series.forEach((element) => {
        result.push({
          name: element.name,
          data: element.values,
          type: this.is_mixed ? element.type : undefined,
        });
      });
      // console.log("series");
      // console.log(result);
      return result;
    },
  },
};
</script>
