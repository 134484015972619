<template>
  <v-row align="center" justify="center">
    <v-flex xs10 sm6 md5 lg4 xl3>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            {{ this.$t("forgot_password") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              prepend-icon="mail_outline"
              :label="$t('email')"
              v-model="email"
              :rules="emailRules"
              required
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="end">
            <v-btn
              color="accent"
              text
              class="body-2"
              @click="onCancel()"
              style="color: white;"
              >{{ this.$t("canceled") }}
            </v-btn>
            <v-btn
              color="accent"
              class="body-2"
              @click="onResetPassword()"
              :disabled="!valid"
              style="color: white;"
              >{{ this.$t("send_renewal_link") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-row>
</template>

<script>
import Constants from "@/Constants";
import { mapActions } from "vuex";

export default {
  name: "RenewPassword",
  created() {
    this.hideProgressBar();
    console.log("RenewPassword_Page_Created");
  },
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [
        (v) => !!v || this.$t("missing_email_message"),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("invalid_email_message"),
      ],
    };
  },
  methods: {
    onCancel() {
      this.showProgressBar;
      this.$router.push({ name: "Login" });
    },
    onResetPassword() {
      if (this.$refs.form.validate()) {
        this.showProgressBar();
        this.$http
          .get(Constants.apiMethod.renewPassword.concat(this.email))
          .then((response) => {
            if (response.data.error_code === 1403) {
              this.$store.state.alertDialog.message = this.$t(
                "no_such_user_is_registered_on_the_system"
              );
              this.$store.state.alertDialog.visibility = true;
              this.hideProgressBar();
            } else {
              console.log("resetPassword success");
              this.$store.state.alertDialog.message = this.$t(
                "renewal_link_sent_message"
              );
              this.$store.state.alertDialog.visibility = true;
              this.$store.state.alertDialog.title = "  ";
              this.$router.push({ name: "Login" });
            }
          });
      }
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>
