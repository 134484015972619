import Vue from "vue";
import App from "./App.vue";
import vueCookie from "vue-cookie";
import vuetify from "./plugins/style/vuetify";
import router from "./router";
import FlagIcon from "vue-flag-icon";
import i18n from "./plugins/language/i18n";
import store from "./store/store";
import VueCryptojs from "vue-cryptojs";
import moment from "moment-timezone";
import vueResource from "vue-resource";
import Constants from "./Constants";
import DeviceUUID from "device-uuid";
import VuePaycard from "vue-paycard";

Vue.use(VuePaycard);
Vue.use(DeviceUUID);
Vue.use(VueCryptojs);
Vue.use(FlagIcon);
Vue.use(vueCookie);
Vue.use(vueResource);

moment.tz.setDefault(moment.tz.guess(true));
Vue.config.productionTip = false;

if (window.location.host === "bgdizayn.akead.net") {
  Vue.http.options.root = Constants.apiUrl;
} else if (window.location.host === "bayi.bgdizayn.com") {
  Vue.http.options.root = Constants.apiUrl;
} else if (window.location.host === "bayi.orfeonegro.com") {
  Vue.http.options.root = Constants.apiUrl;
} else if (window.location.host === "bayi.mianoura.com") {
  Vue.http.options.root = Constants.apiUrl;
} else if (window.location.host === "bayi.celikset.com.tr") {
  Vue.http.options.root = Constants.apiUrl;
} else if (window.location.host === "pro.akead.net") {
  Vue.http.options.root = Constants.apiUrl;
} else if (window.location.host === "protest.akead.net") {
  Vue.http.options.root = Constants.testApiUrl;
} else if (window.location.host === "localhost:8080") {
  //Vue.http.options.root = Constants.testApiUrltestApiUrl;
  Vue.http.options.root = Constants.apiUrl;
}

Vue.http.interceptors.push((request) => {
  // eslint-disable-next-line no-console

  if (
    request.data != null &&
    (request.method === "POST" || request.method === "PUT")
  ) {
    // eslint-disable-next-line no-console

  }
  // Object.defineProperty(Vue.prototype, "$device", {
  //   value: DeviceUUID.DeviceUUID().parse(),
  // });
  // Object.defineProperty(Vue.prototype, "$deviceUUID", {
  //   value: DeviceUUID.DeviceUUID().get(),
  // });

  request.headers.set("Access-Token", vueCookie.get("bgtoken"));
  // request.headers.set('Accept', VueCookie.get('application/json'));)
  // next((response) => {
  //   console.log(
  //     'RESPONSE: '
  //       .concat(response.url)
  //       .concat(' | ')
  //       .concat(response.status)
  //   )
  //   console.log(response.data)
  //   if (response.status !== 401) {
  //     console.log('ERROR!')
  //   }
  // })
});

new Vue({
  vuetify,
  router,
  i18n,
  store,
  VueCryptojs,
  render: (h) => h(App),
}).$mount("#app");
