<template>
    <v-row>
      <v-col class="ma-0 pa-0" >
        <v-card class="elevation-5">
          <v-card-title primary-title style="padding: 18px;">
            <div class="body-2" style="width: 360px; height: 20px; overflow: hidden;">
              <slot name="productGroupsName"></slot>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
</template>
<!--xs6 sm4 md3 lg2-->
<script>
export default {
  name: "ProductGroupView",
};
</script>

<style scoped>
/*.v-card {*/
/*width: 250px;*/
/*!*height: 70px;*!*/
/*}*/
</style>
