import style_data from "./style_data.json"
import meta_data from "./meta_data.json"

const wholesalerConfig = {
  loadStyle(host) {
    if (host === "bgdizayn.akead.net") {
      return style_data.bgDesign
    } else if (host === "bayi.bgdizayn.com") {
      return style_data.bgDesign
    } else if (host === "bayi.orfeonegro.com") {
      return style_data.orfeo
    } else if (host === "bayi.celikset.com.tr") {
      return style_data.celikset      
    } else if (host === "bayi.mianoura.com") {
      return style_data.orfeo
    } else if (host === "pro.akead.net") {
      return style_data.pro      
    } else if (host === "protest.akead.net") {
      return style_data.protest
    } else if (host === "localhost:8080") {
      //return style_data.protest
      return style_data.celikset
    }
  },
  loadMeta(host){
    if (host === "bgdizayn.akead.net") {
      return meta_data.bgDesign.name
    }else if (host === "bayi.bgdizayn.com") {
      return meta_data.bgDesign.name
    } else if (host === "bayi.orfeonegro.com") {
      return meta_data.orfeo.name
    } else if (host === "bayi.celikset.com.tr") {
      return meta_data.celikset.name      
    } else if (host === "bayi.mianoura.com") {
      return meta_data.miaNoura.name
    } else if (host === "pro.akead.net") {
      return meta_data.pro.name      
    } else if (host === "protest.akead.net") {
      return meta_data.protest.name
    } else if (host === "localhost:8080") {
      return meta_data.localhost.name
    }
  },
  loadMenuImg(host){
    if (host === "bgdizayn.akead.net") {
      return require('../../assets/bg_logo.png')
    }else if (host === "bayi.bgdizayn.com") {
      return require('../../assets/bg_logo.png')
    } else if (host === "bayi.orfeonegro.com") {
      return require('../../assets/orfeo_logo.png')
    } else if (host === "bayi.mianoura.com") {
      return require('../../assets/mianoura_logo.jpeg')
    } else if (host === "bayi.celikset.com.tr") {
      return require('../../assets/celikset_logo.png')  
    } else if (host === "pro.akead.net") {
      return require('../../assets/AKEAD_Logo_new_.svg')          
    } else if (host === "protest.akead.net") {
      return require('../../assets/AKEAD_Logo_new_.svg')
    } else if (host === "localhost:8080") {
      return require('../../assets/AKEAD_Logo_new_.svg')
    }
  },
  loadFavicon(host){
    if (host === "bgdizayn.akead.net") {
      return "https://bgdizayn.com/wp-content/uploads/2020/12/logo-50x50.png"
    }else if (host === "bayi.bgdizayn.com") {
      return "https://bgdizayn.com/wp-content/uploads/2020/12/logo-50x50.png"
    } else if (host === "bayi.orfeonegro.com") {
      return "https://orfeonegro.com/wp-content/uploads/2020/12/orfeonegro-logo-100x100.png"
    } else if (host === "bayi.mianoura.com") {
      return "https://mianoura.com/wp-content/uploads/2021/10/logo.jpeg"
    } else if (host === "pro.akead.net") {
      return "http://pro.akead.net/favicon.ico"      
    } else if (host === "protest.akead.net") {
      return "http://protest.akead.net/favicon.ico"
    } else if (host === "localhost:8080") {
      return "http://protest.akead.net/favicon.ico"
    }
  }
};

export default wholesalerConfig;
