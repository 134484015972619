<template>
  <PageTemplate>
    <template slot="title">{{ this.$t("invoice") }}</template>
    <template slot="content">
      <div style="margin: 8px;">
        <v-data-table
          :headers="headers"
          :items="invoiceData"
          no-data-text="Loading"
          hide-default-footer
          class="elevation-7"
        >
          <template #item.quantity="{ item }"
            >{{ item.quantity }} {{ item.unit }}</template
          >
          <template v-slot:item.priceWithoutTax="{ item }">
            {{ fixed(item.priceWithoutTax) }}
            {{
              $store.state.userProfile.currentWholesaler.settings.currencyCode
            }}
          </template>
        </v-data-table>
      </div>
    </template>
  </PageTemplate>
</template>

<script>
import Constants from "@/Constants";
import { mapActions } from "vuex";
import PageTemplate from "../../templates/PageTemplate";
export default {
  name: "OrderPage",
  components: { PageTemplate },
  data() {
    return {
      proWebBasket: null,
      invoice: {},
      quantity2: "",
      index: "",
      invoiceId: "",
      invoiceData: [],
      generatedProduct: {},
      headers: [
        {
          text: this.$t("product"),
          value: "productName",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("quantity"),
          value: "quantity",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("price"),
          value: "priceWithoutTax",
          align: "left",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.invoice = this.$route.params.invoice;
    this.invoiceId = this.$route.params.invoice.id;
    this.$http
      .get(Constants.apiMethod.invoice.concat(this.invoiceId))
      .then((response) => {
        this.invoiceData = response.data;
        console.log(response.data);

        this.hideProgressBar();
      });
  },
  methods: {
    fixed(value) {
      return parseFloat(value).toFixed(2);
    },
    repeatOrder() {
      console.log(this.orderData);
      this.orderData.forEach((element) => {
        this.generateBasketItemAction(element);
      });
    },
    generateBasketItemAction(element) {
      this.$http
        .get(
          Constants.apiMethod.generateBasketItem
            .concat("productId=")
            .concat(element.productId)
            .concat("&quantity=")
            .concat(element.quantity)
            .concat("&unit=")
            .concat(element.unit)
        )
        .then((response) => {
          // console.log(response.data)
          this.generatedProduct = response.data;
          this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
          if (
            this.proWebBasket.find(
              (s) => s.product.code === this.generatedProduct.product.code
            )
          ) {
            this.index = this.proWebBasket.findIndex(
              (s) => s.product.code === this.generatedProduct.product.code
            );
            this.proWebBasket[this.index] = this.generatedProduct;
            localStorage.setItem(
              "proWebBasket",
              JSON.stringify(this.proWebBasket)
            );
          } else {
            this.proWebBasket.push(this.generatedProduct);
            localStorage.setItem(
              "proWebBasket",
              JSON.stringify(this.proWebBasket)
            );
            this.text = this.$t("add_basket_item_success_message");
            this.snackbar = true;
          }
          this.$store.state.badgeCounter = this.proWebBasket.length;
        });
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>

<style scoped></style>
