import { render, staticRenderFns } from "./PageTemplate.vue?vue&type=template&id=71e2f9f9&scoped=true&"
import script from "./PageTemplate.vue?vue&type=script&lang=js&"
export * from "./PageTemplate.vue?vue&type=script&lang=js&"
import style0 from "./PageTemplate.vue?vue&type=style&index=0&id=71e2f9f9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e2f9f9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VAppBarNavIcon,VBadge,VBtn,VIcon,VLayout,VTextField,VToolbarTitle})
