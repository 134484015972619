var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageTemplate',[_c('template',{slot:"title"},[_vm._v(_vm._s(this.$t("documents")))]),_c('template',{slot:"content"},[_c('v-tabs',{staticClass:"elevation-10 ma-1",attrs:{"centered":"","color":"white","dark":"","background-color":"accent"}},[_c('v-tab',{staticClass:"body-2"},[_vm._v(_vm._s(this.$t("orders")))]),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-10",attrs:{"headers":_vm.headers,"items":_vm.ordersData,"no-data-text":"Loading"},on:{"click:row":_vm.itemAction},scopedSlots:_vm._u([{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createDate))+" ")]}},{key:"item.totalWithoutTax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixed(item.totalWithoutTax))+" "+_vm._s(item.currency)+" ")]}}])})],1),_c('v-tab',{staticClass:"body-2"},[_vm._v(_vm._s(this.$t("invoice")))]),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-10",attrs:{"headers":_vm.invoiceHeader,"items":_vm.invoicesData,"no-data-text":"Loading"},on:{"click:row":_vm.invoiceAction},scopedSlots:_vm._u([{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expiryDate))+" ")]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixed(item.totalAmount))+" "+_vm._s(item.currencyCode)+" ")]}}])})],1),_c('v-tab',{staticClass:"body-2"},[_vm._v(_vm._s(this.$t("delivery")))]),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-10",attrs:{"headers":_vm.deliveriesHeader,"items":_vm.deliveriesData,"no-data-text":"Loading"},on:{"click:row":_vm.invoiceAction},scopedSlots:_vm._u([{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixed(item.totalAmount))+" "+_vm._s(item.currencyCode)+" ")]}}])})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }