<template>
  <div>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="
        $store.state.navigationDrawer = !$store.state.navigationDrawer
      " />
      <v-toolbar-title>
        <slot name="title" />
      </v-toolbar-title>
      <v-layout align-center justify-end v-show="$store.state.menuRestriction">
          
        <v-text-field hide-details :placeholder="$t('search_products')"
          v-model="$store.state.productSearchConstants.productSearchText" prepend-inner-icon="search"
          style="max-width: 280px; margin-bottom: -2px" @keyup.enter="
            searchProduct($store.state.productSearchConstants.productSearchText)
          " />
        <div class="toggleButtons">
          <v-btn icon text dark color=" white" class="btnToggle" v-if="this.toggle" @click="changeToggleFalse()">
            <img src="../../assets/Icon_listing.svg" style="width: 1.2rem;">
          </v-btn>
          <v-btn icon text dark color="white" class="btnToggle" v-if="!this.toggle" @click="changeToggleTrue()">
            <img src="../../assets/Icon_listing2.svg" style="width: 1rem;">
          </v-btn>
        </div>
        <v-btn icon text dark color="white" style="top: 3px;" @click="basketAction()">
          <v-badge top color="accent" overlap :content="this.$store.state.badgeCounter"
            :value="this.$store.state.badgeCounter">
            <v-icon dark>shopping_cart</v-icon>
          </v-badge>
        </v-btn>
        <v-btn icon style="top: 2px; left: 2px;" @click.stop="$store.state.buttonTry = !$store.state.buttonTry"
          v-show="$store.state.dashboardRestriction">
          <v-icon dark>table_chart</v-icon>
        </v-btn> 
           <slot class="deskop"  name="sortMenu"></slot>
      </v-layout>
       <!-- <div class="MobilMenu">
            <v-btn icon text dark color="white" class="menuToggle" @click="CloseOpenMenu" >
              <img class="Mobil" src="../../assets/MobilMenu.svg" >
            </v-btn>
            <ul class="ListMenu" v-if="MobilMenu">
              <li @click="changeToggleFalse()">
                List
              </li>
              <li @click="changeToggleTrue()">
                Card
              </li>
              <li @click="showModal()" >
                Detailed Search
              </li>
                <slot name="sortMenu"></slot>
            </ul>
        </div> -->
    </v-app-bar>
    <!--        <v-content style="background-color: blue">-->
    <slot name="content" />
    <!--        </v-content>--> 
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PageTemplate",
  data() {
    return {
      toggle: this.$store.state.userProfile.currentWholesaler.settings.defaultProductListType == 2 ? true : false,
      MobilMenu: false,
    };
  },
  methods: {
    showModal() {
      this.$store.state.detailedSearch = true;
      this.MobilMenu = !this.MobilMenu;
    },
    CloseOpenMenu() {
      console.log("CloseOpenMenu");
      this.MobilMenu = !this.MobilMenu;
    },

    changeToggleFalse() {
      this.toggle = false;
      this.$emit("changeToggle", this.toggle);
    },
    changeToggleTrue() {
      this.toggle = true;
      this.$emit("changeToggle", this.toggle);
    },

    basketAction() {
      this.$router.push({ name: "Basket" });
    },
    searchProduct(text) {
      if (
        text.length !== 0 &&
        this.$router.currentRoute.name !== "SearchProduct"
      ) {
        this.$router.push({
          name: "SearchProduct",
          params: { productSearch: text },
        });
      } else {
        this.$store.dispatch("searchProduct");
      }
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};

</script>

<style scoped>
/* .menuToggle{
  margin-left: 0;
  width: 1rem !important;
} */
/* .deskop{
  display: block;
}
.Mobil{
  width: .4rem;
} */

/* .ListMenu{
  list-style: none;
  position: absolute;
  top: 3rem;
  right: 0;
  background-color: #fff;
  border-radius: 0 0 0 1rem;
  box-shadow: 0 0 1rem rgba(0,0,0,.2);
  z-index: 1;
  color: black !important;
  width: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.ListMenu li{
  width: 100%;
  padding: .1rem;
  text-align: center;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  color: black !important;
} */
.toggleButtons {
  margin: 0 1rem;
  width: 2rem;
  display: flex;
  border-radius: 0 0 !important;
  padding: 0 !important;
}

.btnToggle {
  margin: 0 .1rem;
  border-radius: 0 0 !important;
  transition: ease-in-out 10s;
  border-radius: 50% !important;
}
/* 
.MobilMenu{
  display: none;
} */

/* @media only screen and (max-width: 600px) {
  .MobilMenu{
    font-family: 'Roboto', sans-serif;
    display: block;
  }
  .deskop{
    display: none !important;
  }
  .toggleButtons{
    display: none;
  }
} */
</style>
