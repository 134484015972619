<template>
  <v-dialog v-bind:value="value" max-width="360">
    <v-card>
      <v-card-title class="headline">{{ currentTitle }}</v-card-title>

      <v-card-text>{{ content }}</v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="handleClose">{{ this.$t("ok") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value", "title", "content", "buttontext"],
  watch: {
    // eslint-disable-next-line no-unused-vars
    value: function (newValue, oldValue) {
      if (newValue) {
        this.currentTitle =
          this.title == null || this.title === ""
            ? this.$t("error")
            : this.title;
        this.currentButtonText =
          this.buttontext === null || this.buttontext === ""
            ? this.$t("okey")
            : this.buttontext;
      }
    },
  },
  data() {
    return {
      currentTitle: this.$t("error"),
      currentButtonText: this.$t("ok"),
    };
  },
  methods: {
    handleClose() {
      this.$emit("input", false);
      this.$store.dispatch("hideProgressBar");
    },
  },
};
</script>
