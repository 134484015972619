var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageTemplate',[_c('template',{slot:"title"},[_vm._v(_vm._s(this.$t("account_activities"))+" ")]),_c('template',{slot:"content"},[_c('div',{staticStyle:{"margin":"8px"}},[_c('v-data-table',{staticClass:"elevation-10",attrs:{"headers":_vm.headers,"items":_vm.newItem,"no-data-text":"no item in Account Activities","loading-text":_vm.$t('loading').concat('...'),"footer-props":{'items-per-page-options':[15, 30, 50, 100,200,-1]},"options":_vm.options},scopedSlots:_vm._u([{key:"item.dat_pay",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dat_pay)))])]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [(item.debit === '0' ? '': item.debit)?_c('span',[_vm._v(_vm._s(item.debit)+" "+_vm._s(item.currency_reg))]):_vm._e()]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [(item.credit === '0' ? '': item.credit)?_c('span',[_vm._v(_vm._s(item.credit)+" "+_vm._s(item.currency_reg)+" ")]):_vm._e()]}},{key:"item.remainder",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.remainder)+" "+_vm._s(item.currency_reg))])]}},{key:"item.debit_main",fn:function(ref){
var item = ref.item;
return [(item.debit_main === '0' ? '': item.debit_main)?_c('span',[_vm._v(_vm._s(item.debit_main)+" "+_vm._s(_vm.$store.state.userProfile.currentWholesaler.settings.currencyCode))]):_vm._e()]}},{key:"item.credit_main",fn:function(ref){
var item = ref.item;
return [(item.credit_main === '0' ? '': item.credit_main)?_c('span',[_vm._v(_vm._s(item.credit_main)+" "+_vm._s(_vm.$store.state.userProfile.currentWholesaler.settings.currencyCode))]):_vm._e()]}},{key:"item.remainder_main",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.remainder_main)+" "+_vm._s(_vm.$store.state.userProfile.currentWholesaler.settings.currencyCode))])]}}])},[_vm._v("1 "),_vm._v(" $store.state.userProfile.currentWholesaler.settings.currencyCode ")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }