<template>
  <v-container fill-height>
    <v-col align="center" justify="center">
      <v-icon size="256">mdi-sign-direction-remove</v-icon>
      <h1 class="my-12" v-if="data!== 'no_user_wholesaler_found'">{{this.$t("no_item_found")}}</h1>
      <h1 class="my-12" v-if="data=== 'no_user_wholesaler_found'">{{this.$t("invitation_code_dialog_info")}}</h1>
      <h1 class="my-12" v-if="data=== 'no_user_wholesaler_found'">{{this.$t("no_user_wholesaler_found")}}</h1>
      <!--      <v-row align="center" justify="center">-->
      <!--        &lt;!&ndash;        <v-btn class="ma-2" @click="$router.go(-1)">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <v-icon left>mdi-arrow-left</v-icon>Back&ndash;&gt;-->
      <!--        &lt;!&ndash;        </v-btn>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <v-btn class="ma-2" link to="/">&ndash;&gt;-->
      <!--        &lt;!&ndash;          Home&ndash;&gt;-->
      <!--        &lt;!&ndash;          <v-icon right>home</v-icon>&ndash;&gt;-->
      <!--        &lt;!&ndash;        </v-btn>&ndash;&gt;-->
      <!--      </v-row>-->
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    data: String
  }
};
</script>
