<template>
  <v-row align="center" justify="center">
    <v-flex xs10 sm7 md5 lg3 xl3>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ this.$t("register") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              prepend-icon="person"
              name="username"
              :label="this.$t('username')"
              type="text"
              v-model="registerData.username"
              :rules="userNameRules"
              required
            />

            <v-text-field
              prepend-icon="lock"
              name="password"
              :label="this.$t('password')"
              id="password"
              autocomplete="off"
              v-model="registerData.password"
              type="password"
              :rules="passWordRules"
              required
            />

            <v-text-field
              prepend-icon="lock"
              name="passConfirmation"
              :label="this.$t('password_repeat')"
              autocomplete="off"
              id="passConfirmation"
              type="password"
              v-model="passConfirmation"
              :rules="[comparePasswords]"
            />

            <v-text-field
              prepend-icon="mail_outline"
              name="mail"
              :label="this.$t('email')"
              id="mail"
              type="mail"
              v-model="registerData.email"
              :rules="mailRules"
            />

            <v-text-field
              prepend-icon="assignment_ind"
              name="name"
              :label="this.$t('name')"
              id="name"
              type="text"
              v-model="registerData.name"
              :rules="nameRules"
              required
            />

            <v-text-field
              prepend-icon="assignment_ind"
              name="last_name"
              :label="this.$t('last_name')"
              id="surname"
              type="text"
              v-model="registerData.last_name"
              :rules="surnameRules"
              required
            />

            <v-text-field
              prepend-icon="phone"
              name="phone"
              :label="this.$t('phone_number')"
              id="phone"
              type="text"
              v-model="registerData.phone_number"
              required
            />

            <v-text-field
              prepend-icon="work_outline"
              name="taxNumber"
              :label="this.$t('tax_number')"
              id="taxNumber"
              type="text"
              v-model="registerData.tax_number"
              required
            />

            <v-text-field
              prepend-icon="work_outline"
              name="companyName"
              :label="this.$t('company_name')"
              id="companyName"
              type="text"
              v-model="registerData.company_name"
              required
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="end">
            <v-btn text class="body-2" color="accent" @click="onCancel()"
              >{{ this.$t("canceled") }}
            </v-btn>
            <v-btn
              color="accent"
              @click="onRegister()"
              :disabled="!valid"
              style="color: white;"
              >{{ this.$t("register") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import Constants from "@/Constants";
export default {
  name: "Register",
  data() {
    return {
      registerData: {
        email: "",
        password: "",
        username: "",
        name: "",
        last_name: "",
        phone_number: "",
        tax_number: "",
        company_name: "",
        device_universal_id: "123456",
        device_name: "deneme",
        device_type: "OTHER",
      },
      passConfirmation: "",
      valid: false,
      userNameRules: [(v) => !!v || "Username is required"],
      passWordRules: [(v) => !!v || "Password is required"],
      mailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      nameRules: [(v) => !!v || "Name is required"],
      surnameRules: [(v) => !!v || "Surname is required"],
      rule: "Passwords do not match"
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    comparePasswords() {
      if (this.registerData.password !== this.passConfirmation) {
        return this.rule;
      }
      else console.log("");
    },
  },
  created() {
    this.hideProgressBar();
  },
  methods: {
    onCancel() {
      this.showProgressBar();
      this.$router.push({ name: "Login" });
    },
    onRegister() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("showProgressBar");
        this.$http
          .post(Constants.apiMethod.register, this.registerData)
          .then((response) => {
            console.log(response.data);
            if (response.data.error_code === 1003) {
              this.$store.state.alertDialog.message = this.$t(
                "this_email_has_registered_to_AKEAD_Pro"
              );
              this.$store.state.alertDialog.visibility = true;
              this.registerFailed();
            } else if (response.data.error_code === 1006) {
              this.registerFailed();
              window.alert(this.$t("this_username_has_registered_to_AKEAD_Pro"));
            } else {
              this.afterRegister();
            }
          });
      }
    },
    afterRegister() {
      this.$router.push({ name: "Login" });
    },
    registerFailed() {
      this.hideProgressBar();
      this.$refs.form.reset();
    },
    ...mapActions(["showProgressBar", "hideProgressBar"]),
  },
};
</script>
