import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.min.css'
import wholesalerConfig from "@/plugins/style/wholesalersConfig";

Vue.use(Vuetify);
// import i18n from "@/plugins/language/i18n";
export default new Vuetify({
    theme: {
        dark: false,
        themes: wholesalerConfig.loadStyle(window.location.host)
    },
    icons: {
        iconfont: 'md',
    },
  // lang: {
  //   t: (key, ...params) => i18n.t(key, params),
  // },
});
