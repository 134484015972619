<template>
    <div class="list_item">

        <div class="box-container">
            <div class="ribbon-container">
                <div class="ribbon-list" v-if="product.campaignCode !== null || product.campaignCode === ''">
                    <span v-if="product.campaignCode === 'NEW'">{{ $t('new') }}</span>
                    <span v-else-if="product.campaignCode === 'STOCK'">{{ $t('stock') }}</span>
                    <span v-else-if="product.campaignCode === 'PROMO'">{{ $t('promotion') }}</span>
                </div>
            </div>
            <div class="img-box" @click="onClickProduct(product)">
                <v-img class="img" contain src="../../../src/assets/box_opened_vector_.png" alt v-show="!imageShow">
                </v-img>
                <v-img class="img" contain v-show="imageShow" :src="getImageUrl()" />
            </div>
            <div class="right-box">
                <div class="description" :title="product.name" @click="onClickProduct(product)">
                    {{ product.code }} {{ product.name }}
                </div>
                <div class="bottom-box">
                    <div class="price-stock"  @click="onClickProduct(product)">
                        <div class="price"> {{ (product.uDisplayPrice * product.packagingQuantity).toFixed(2) }}
                            {{ product.priceCurrencyCode }}
                        </div>
                        <div class="stock">{{ $t("stock") }}
                            {{ $t("quantity") }} : {{ this.displayProductStock }} </div>
                    </div>
                    <div v-if="piece > 0" text icon color="white" class="buttons primary">
                        <v-btn text icon color="white" class=" btn" @click="decrease()">
                            <v-icon>remove</v-icon>
                        </v-btn>
                        <span>{{ piece }} </span>
                        <v-btn text icon color="white" class=" btn" @click="increase()">
                            <v-icon>add</v-icon>
                        </v-btn>

                    </div>
                    <div text icon color="white" class="buttons accent" v-if="piece <= 0">
                        <v-btn text icon color="white" class=" btn" @click="decrease()">
                            <v-icon text icon color="white">remove</v-icon>
                        </v-btn>
                        <span>{{ piece }} </span>
                        <v-btn class=" btn" @click="increase()">
                            <v-icon text icon color="white">add</v-icon>
                        </v-btn>

                    </div>
                </div>
            </div>
        </div>
        <v-snackbar v-model="snackbar" center top color="accent" :multi-line="mode === 'multi-line'" :timeout="timeout"
            :vertical="mode === 'vertical'">
            <v-row align="center" justify="center">{{ text }}</v-row>
        </v-snackbar>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Constants from "@/Constants";

export default {
    name: "ProductListItem",
    props: {
        product: null,
    },
    created() {
        if (this.product.campaignCode === "") {
            this.product.campaignCode = null;
        }
        if (localStorage.getItem("proWebBasket") == null || localStorage.getItem("proWebBasket") === "") {
            localStorage.setItem("proWebBasket", JSON.stringify([]));
        }
        this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
        if (this.proWebBasket.find((s) => s.product.code === this.product.code)) {
            var searchPro = this.proWebBasket.find(
                (s) => s.product.code === this.product.code
            );
            this.actionBar = !this.actionBar;
            this.piece = searchPro.quantity;
        }
        this.$store.state.campaignColors.forEach((item) => {
            if (item.code === this.product.campaignCode) {
                this.ribbon = this.ribbon + item.color;
            }
        })
        this.displayProductStock = this.product.stockQuantity > this.$store.state.userProfile.currentWholesaler.settings.stockDisplayLimit ? this.$store.state.userProfile.currentWholesaler.settings.stockDisplayLimit + "+" : this.product.stockQuantity
    },
    data() {
        return {
            ribbon: "background: #",
            snackbar: false,
            mode: "",
            timeout: 3000,
            text: null,
            actionBar: false,
            index: null,
            imageShow: true,
            generatedProduct: {},
            proWebBasket: {},
            piece: 0,
            style: "",
            displayProductStock: "",

        };
    },
    methods: {
        onClickProduct(product) {
            if (product.uDisplayPrice !== null) {
                this.$router.push({
                    name: "ProductDetail",
                    params: { id: product.id },
                });
                this.showProgressBar();
            }
        },
        decrease() {
            if (this.piece <= 0) {
                return;
            }
            this.piece--;
        },
        increase() {
            this.piece++;
        },
        getImageUrl() {
            var result = "";
            if (this.product.productImages.length > 0) {
                if (this.product.productImages[0].mediumThumbnailUrl) {
                    this.style = "height:45%;";
                    result = this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
                        this.product.productImages[0].mediumThumbnailUrl
                    );
                } else if (this.product.productImages[0].smallThumbnailUrl) {
                    result = this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
                        this.product.productImages[0].smallThumbnailUrl
                    );
                    this.style = "height:70%;";
                } else if (this.product.productImages[0].largeThumbnailUrl) {
                    result = this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
                        this.product.productImages[0].largeThumbnailUrl
                    );
                } else {
                    result = this.$store.state.userProfile.currentWholesaler.fileUrlRoot.concat(
                        this.product.productImages[0].url
                    );
                    this.style = "height:80%;margin-top:10px";
                }
            } else {
                this.imageShow = false;
            }
            return result;
        },
        onCardActions() {
            this.actionBar = !this.actionBar;
            this.piece++;
        },
        ...mapActions(["showProgressBar", "hideProgressBar"]),
    },
    watch: {
        piece() {
            if (this.piece === 0) {
                this.proWebBasket = JSON.parse(localStorage.getItem("proWebBasket"));
                this.index = this.proWebBasket.findIndex(
                    (s) => s.product.code === this.generatedProduct.product.code
                );
                this.proWebBasket.splice(this.index, 1);
                console.log(this.proWebBasket);
                localStorage.setItem("proWebBasket", JSON.stringify(this.proWebBasket));
                this.actionBar = !this.actionBar;
                if (this.product.stockQuantity !== 0) {
                    this.$store.state.badgeCounter--;
                    this.text = this.$t("remove_basket_item_success_message");
                    this.snackbar = true;
                }
            } else if (this.product.uDisplayPrice !== null) {
                if (this.piece === this.product.stockQuantity + 1 && !this.$store.state.userProfile.currentWholesaler.settings.sellStocklessProducts) {
                    this.piece = this.product.stockQuantity
                } else {
                    this.$http
                        .get(
                            Constants.apiMethod.generateBasketItem
                                .concat("productId=")
                                .concat(this.product.id)
                                .concat("&quantity=")
                                .concat(this.piece)
                                .concat("&unit=")
                                .concat(this.product.packageUnit)
                        )
                        .then((response) => {
                            // console.log(response.data)
                            this.generatedProduct = response.data;
                            console.log(response.data)
                            this.proWebBasket = JSON.parse(
                                localStorage.getItem("proWebBasket")
                            );
                            if (
                                this.proWebBasket.find(
                                    (s) => s.product.code === this.generatedProduct.product.code
                                )
                            ) {
                                this.index = this.proWebBasket.findIndex(
                                    (s) => s.product.code === this.generatedProduct.product.code
                                );
                                this.proWebBasket[this.index] = this.generatedProduct;
                                localStorage.setItem(
                                    "proWebBasket",
                                    JSON.stringify(this.proWebBasket)
                                );
                            } else {
                                this.proWebBasket.push(this.generatedProduct);
                                localStorage.setItem(
                                    "proWebBasket",
                                    JSON.stringify(this.proWebBasket)
                                );
                                this.text = this.$t("add_basket_item_success_message");
                                this.snackbar = true;
                            }
                            this.$store.state.badgeCounter = this.proWebBasket.length;
                        });

                }
            }

            console.log(this.piece);
        },
    },
};


</script>

<style >
.list_item {
    width: 100%;
}


.box-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 100%;
    margin: 10px auto;
    padding: 2px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    font-family: Roboto, sans-serif;
    font-size: 14.65px;
}



.ribbon-list {
    -ms-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    padding: 0 1.1rem;
    font: 700 18px/1 "Lato", sans-serif;
    background-color: #388e3c;
    margin-bottom: -.5rem !important;
    text-shadow: 0 3px px rgba(0, 0, 0, 0.7);
    color: #fff;
    margin-left: -3.2rem;
    margin-top: -.8rem;
    position: absolute;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.box-container:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

/* 
-ms-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); */


.img-box {
    width: 5rem;
    height: 5rem;
    cursor: pointer;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.right-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
}

.description {
    width: 100%;
    height: 100%;
    cursor: pointer;
    /* padding: 0 1rem; */
    margin: .5rem 0;
    /* justify-content: center;
    display: flex; */
}

.bottom-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: .2rem;
    padding: 0 .2rem;

}

.price-stock {
    display: flex;
    flex-direction: row;
    width: 60%;
    cursor: pointer;
}

.price {
    color: #000;
    width: 47%;
    justify-content: center;
    display: flex;
}

.stock {
    color: #000;
    width: 50%;
    justify-content: center;
    display: flex;
}

.buttons {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    justify-content: space-between;
    color: white;
}

.btn {
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease;
    height: 70% !important;
    border-radius: 0 !important;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    width: 2rem !important;
    min-width: 2rem !important;
}

.btn:hover {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
}
</style>