<template>
  <page-template @changeToggle="toggle = $event">
    <template slot="title">{{ this.$t("favorite_products") }}</template>
    <template slot="content">

      <v-layout align-center justify-start row wrap style="margin: 0;">
        <v-layout align-center justify-start row wrap style="margin: 0;" v-if="!this.toggle">
          <ProductListItem v-show="!noData" v-for="product in favoriteProducts" :key="product.key" :product="product" />

        </v-layout>

        <v-layout align-center justify-start row wrap style="margin: 0;" v-if="this.toggle">
          <product-view v-show="!noData" v-for="product in favoriteProducts" :key="product.key" :product="product" />

        </v-layout>
        <not-found v-show="noData" />
      </v-layout>

    </template>
    <template slot="sortMenu">
      <v-menu bottom left style="z-index: 10;">
        <template v-slot:activator="{ on }">
          <v-btn class="deskop" dark icon v-on="on">
            <img  src="../../../assets/Icon_up-down.svg" style="width: 1.6rem;">
          </v-btn>
          <!-- <button class="Mobil" v-on="on">
            SortMenu
          </button> -->
        </template>

        <v-list dense>
          <v-list-item @click="byNameASC()">
            <v-list-item-title>{{ this.$t("by_name_asc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byNameDESC()">
            <v-list-item-title>{{ this.$t("by_name_desc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byCodeASC()">
            <v-list-item-title>{{ this.$t("by_code_asc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byCodeDESC()">
            <v-list-item-title>{{ this.$t("by_code_desc") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="byGroup()">
            <v-list-item-title>{{ this.$t("by_group") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </page-template>
</template>

<script>
import Constants from "@/Constants";
import PageTemplate from "../../templates/PageTemplate";
import { mapActions } from "vuex";
import ProductView from "../../partial/ProductView";
import NotFound from "../scada/NotFound";
import ProductListItem from "@/components/partial/ProductListItem";

export default {
  name: "HighlightedProducts",
  components: {
    NotFound,
    PageTemplate,
    ProductView,
    ProductListItem
  },
  created() {
    this.getFavoriteProducts();
   
  },
  data() {
    return {
      noData: false,
      favoriteProducts: {},
      toggle: this.$store.state.userProfile.currentWholesaler.settings.defaultProductListType == 2 ? true : false
    };
  },
  methods: {
    getFavoriteProducts() {
      this.$store.dispatch("showProgressBar")
      this.$http.get(Constants.apiMethod.favoriteProducts).then((response) => {
        this.favoriteProducts = response.data;
        console.log(response.data);
        this.$store.dispatch("hideProgressBar");
      });
    },

    byNameASC() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },
    byNameDESC() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },
    byCodeASC() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.code > b.code ? 1 : -1
      );
    },
    byCodeDESC() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.code < b.code ? 1 : -1
      );
    },
    byGroup() {
      this.highlightedProducts = this.highlightedProducts.sort((a, b) =>
        a.productGroup.code < b.productGroup.code ? 1 : -1
      );
    },
    ...mapActions(["showProgressBar", "hideProgressBar", "loadUserProfile"]),
  },

};
</script>

<style scoped>

/* .Mobil{
  display: none;
}

@media only screen and (max-width: 600px) {
  .deskop {
    display: none;
  }
  .Mobil{
    display: block;
    color: black !important;
    background: white !important;
    width: 100%;
    height: 100%;
  }
} */
</style>
