/* eslint-disable */
<template>
  <div class="iqvchart">
    <v-card-title dense v-if="data.title">{{ data.title }}</v-card-title>
    <v-progress-linear v-if="my_progressing" indeterminate color="accent" />
    <s-chart
      v-if="is_s_chart"
      :data="data"
      :dark="dark"
      :height="my_chart_height"
    />
    <psv-chart
      v-else-if="is_psv_chart"
      :data="data"
      :dark="dark"
      :height="my_chart_height"
    />
  </div>
</template>

<script>
import SChart from "./SChart";
import PsvChart from "./PsvChart";

export default {
  name: "iqv-chart",
  props: ["data", "dark", "progressing", "height"],
  // watch: {
  //   data: function(new_value, old_value) {
  //     this.handleData();
  //   }
  // },
  components: {
    SChart,
    PsvChart,
  },
  computed: {
    my_progressing() {
      return this.progressing || false;
    },
    my_chart_height() {
      return this.height - 72;
    },
    type() {
      return this.data.type;
    },
    is_pie() {
      return this.type === "pie";
    },
    is_donut() {
      return this.type === "donut";
    },
    is_bar() {
      return this.type === "bar";
    },
    is_line() {
      return this.type === "line";
    },
    is_area() {
      return this.type === "area";
    },
    is_mixed() {
      return this.type === "mixed";
    },
    is_bubble() {
      return this.type === "bubble";
    },
    is_scatter() {
      return this.type === "scatter";
    },
    is_heatmap() {
      return this.type === "heatmap";
    },
    is_s_chart() {
      return this.is_pie || this.is_donut;
    },
    is_psv_chart() {
      return this.is_bar || this.is_line || this.is_area || this.is_mixed;
    },
    is_svd_value() {
      return this.is_scatter;
    },
    is_svt_value() {
      return this.is_bubble;
    },
  },
  created() {
    // console.log(this);
    // if (this.is_multi_period) console.log(this.type);
    // this.handleData();
  },
};
</script>
<style>
.apexcharts-canvas.apexcharts-theme-dark {
  /* background: #424242; */
  background: transparent !important;
}
.iqvchart {
  padding: 0px;
}
.iqvchart .v-card__title {
  font-size: 20px;
  padding: 16px;
}
.apexcharts-toolbar {
  z-index: 4;
}
</style>
